<script lang="ts" setup>

  const { t } = useI18n()

  useHead({
    title: t("company_name"),
    link: [
      { rel: "icon", href: "/favicon.svg", type: "image/svg+xml" }
    ]
  })

</script>

<template>

  <!-- Router View -->
  <main>
    <router-view></router-view>
  </main>

</template>

<style lang="css">

  main {
    width: 100%;
  }

</style>